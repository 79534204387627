import React from "react"

import Layout from 'components/Layout/Layout';
import Error from 'components/Error/Error';
import SEO from 'components/seo';

const TimeoutPage = () => (
  <Layout displayProgressBar={false}>
    <SEO title="Timed out" />
    <Error timeout={true} />
  </Layout>
)

export default TimeoutPage
